import { ArrowForward } from '@material-ui/icons';
import { Link } from 'gatsby';
import React, { useContext } from 'react';
import { LangContext } from '../Layout';
import Img from 'gatsby-image';
import './style.scss';

const JoinNW = ({ data }) => {
  const { language } = useContext(LangContext);
  return (
    <div className="join-nw">
      <div className="join-nw__left">
        <div className="join-nw__left__title">{data['title_' + language]}</div>
        <div className="join-nw__left__reasons">
          {data.reasons.map((reason, index) => (
            <div
              key={index}
              className="join-nw__left__reasons__reason"
              style={{ color: index % 2 == 0 && '#EB514E' }}
            >
              {reason['reason_' + language]}
              <br />
            </div>
          ))}
        </div>
        <Link to="https://jobs.lever.co/theodo?department=Theodo%20Maroc%20%28ex-NimbleWays%29">
          <button className="join-nw__left__button">
            {data['button_' + language]}
            <ArrowForward fontSize="small" className="materiel-icon" />
          </button>
        </Link>
      </div>
      <div className="join-nw__right">
        <div className="join-nw__right__desktop">
          <Img
            className="join-nw__right__image"
            fluid={data.image.childImageSharp.fluid}
            alt={data['title_' + language]}
            style={{ position: 'absolute' }}
          />
        </div>
        <div className="join-nw__right__mobile">
          <Img
            className="join-nw__right__image"
            fluid={data.image.childImageSharp.fluid}
            alt={data['title_' + language]}
          />
        </div>
      </div>
    </div>
  );
};

export default JoinNW;
